import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IntegrationService } from '../../services/integration.service';

@Component({
  selector: 'app-auth-integration',
  templateUrl: './auth-integration.component.html',
  styleUrls: ['./auth-integration.component.css']
})
export class AuthIntegrationComponent implements OnInit {

  constructor(route: ActivatedRoute, private integrationService: IntegrationService, private router: Router) { 
    route.params.subscribe((params) => {
      const origin = params.origin
      route.queryParams.subscribe(query => {
        const code = query.code
        if(origin === 'rd')
          this.authorizeRd(code)
        else if(origin === 'zoho')
          this.authorizeZoho(code)
      })
    })
  }

  ngOnInit(): void {
  }

  authorizeRd(code: string) {
    this.integrationService.authorizeRd(code)
      .then(() => alert('Integração realizada com sucesso'))
      .catch(() => alert('Erro ao realizar autenticação'))
      .finally(() => this.router.navigate(['']))
  }

  authorizeZoho(grantToken: string) {
    this.integrationService.authorizeZoho(grantToken)
      .then(() => alert('Integração realizada com sucesso'))
      .catch(() => alert('Erro ao realizar autenticação'))
      .finally(() => this.router.navigate(['']))
  }

}
