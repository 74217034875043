import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './views/auth/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { HttpClientModule } from '@angular/common/http';
import { IntegrationComponent } from './views/integration/integration.component'
import { AuthService } from './services/auth.service';
import { AuthGuardService } from './services/auth-guard.service';
import { LocalstorageService } from './services/local-storage.service';
import { AuthIntegrationComponent } from './views/auth-integration/auth-integration.component';
import { RouterModule } from '@angular/router';
import { EventsComponent } from './views/events/events.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    IntegrationComponent,
    AuthIntegrationComponent,
    EventsComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule
  ],
  providers: [
    AuthService,
    AuthGuardService,
    LocalstorageService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
