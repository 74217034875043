<div class="row" *ngIf="integration">
    <div class="event card col-md-12 col-xs-12">
        <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" [(ngModel)]="integration.events.rd.sendOpportunity.active">
            <label class="form-check-label" for="flexCheckDefault">
              Enviar opotunidade do RD apra o Zoho
            </label>
        </div>
        <input type="text" [(ngModel)]="integration.events.rd.sendOpportunity.module">
        <input type="text" disabled [(ngModel)]="integration.events.rd.sendOpportunity.url + '?code=' + integration.userId">
        <button class="btn btn-primary" (click)="copy(integration.events.rd.sendOpportunity.url + '?code=' + integration.userId)"><i class="fa fa-copy"></i></button>
        
        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" [(ngModel)]="integration.events.rd.sendOpportunity.update">
        <label class="form-check-label" for="flexCheckDefault">
            Atualizar registro já existente?
        </label>
    </div>
    <div class="event card col-md-12 col-xs-12">
        <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" [(ngModel)]="integration.events.rd.sendUUID.active">
            <label class="form-check-label" for="flexCheckDefault">
              Enviar UUID do lead quando criado no RD para o Zoho
            </label>
        </div>
        <input type="text" disabled [(ngModel)]="integration.events.rd.sendUUID.url + '?code=' + integration.userId">
        <button class="btn btn-primary" (click)="copy(integration.events.rd.sendUUID.url + '?code=' + integration.userId)"><i class="fa fa-copy"></i></button>
    </div>
    <div class="event card col-md-12 col-xs-12">
        <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" [(ngModel)]="integration.events.rd.sendUpdate.active">
            <label class="form-check-label" for="flexCheckDefault">
              Enviar alteração do lead no RD para o Zoho
            </label>
        </div>
        <input type="text" disabled [(ngModel)]="integration.events.rd.sendUpdate.url + '?code=' + integration.userId">
        <button class="btn btn-primary" (click)="copy(integration.events.rd.sendUpdate.url + '?code=' + integration.userId)"><i class="fa fa-copy"></i></button>
    </div>
    <div class="event card col-md-12 col-xs-12">
        <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" [(ngModel)]="integration.events.zoho.changeEmail.active">
            <label class="form-check-label" for="flexCheckDefault">
              Enviar Alteração de e-mail do Zoho para o RD
            </label>    
        </div>
        <input type="text" disabled [(ngModel)]="integration.events.zoho.changeEmail.url + '?code=' + integration.userId">
        <button class="btn btn-primary" (click)="copy(integration.events.zoho.changeEmail.url + '?code=' + integration.userId)"><i class="fa fa-copy"></i></button>
    </div>
    <div class="event card col-md-12 col-xs-12">
        <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" [(ngModel)]="integration.events.zoho.sendWonLost.active">
            <label class="form-check-label" for="flexCheckDefault">
              Enviar perda e ganho do Zoho para o RD
            </label>
        </div>
        <input type="text" disabled [(ngModel)]="integration.events.zoho.sendWonLost.url + '?code=' + integration.userId">
        <button class="btn btn-primary" (click)="copy(integration.events.zoho.sendWonLost.url + '?code=' + integration.userId)"><i class="fa fa-copy"></i></button>
    </div>
    <!-- <div class="event card col-md-12 col-xs-12">
        <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" [(ngModel)]="integration.events.zoho.sendLead.active">
            <label class="form-check-label" for="flexCheckDefault">
              Enviar novo Lead do Zoho para o RD
            </label>
        </div>
        <input type="text" disabled [(ngModel)]="integration.events.zoho.sendLead.url + '?code=' + integration.userId">
        <button class="btn btn-primary" (click)="copy(integration.events.zoho.sendLead.url + '?code=' + integration.userId)"><i class="fa fa-copy"></i></button>
    </div> -->
    <button class="btn btn-primary save-button" (click)="save()">Salvar</button>
</div>