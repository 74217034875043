import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './views/auth/login/login.component';
import { IntegrationComponent } from './views/integration/integration.component';
import { AuthGuardService } from './services/auth-guard.service';
import { AuthIntegrationComponent } from './views/auth-integration/auth-integration.component'
import { EventsComponent } from './views/events/events.component';


const routes: Routes = [
  {
    path: '',
    component: IntegrationComponent,
    canActivate: [AuthGuardService]
  },
  {
    //Url que recebe o código de autorização do RD ou Zoho
    path: 'auth/:origin',
    component: AuthIntegrationComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'events',
    component: EventsComponent,
    canActivate: [AuthGuardService]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
