import { Component, OnInit } from '@angular/core';
import { IntegrationService } from '../../services/integration.service';
import { LocalstorageService } from '../../services/local-storage.service';

@Component({
  selector: 'app-integration',
  templateUrl: './integration.component.html',
  styleUrls: ['./integration.component.css']
})
export class IntegrationComponent implements OnInit {

  isLoggedInRd() { return this.integration.rd.accessToken }
  isLoggedInZoho() { return this.integration.zoho.accessToken }
  rdUrl: string = ''
  zohoUrl: string = ''

  integration: any = {
    userId: '',
    zoho: {
        accessToken: '',
        grantToken: '',
        location: '',
        refreshToken: '',
        server: ''
    },
    rd: {
        accessToken: '',
        refreshToken: ''
    },
    fields: []
  }

  rdFields: Array<any>
  zohoFields: Array<any>
  chosenFields: Array<any>

  constructor(private integrationService: IntegrationService, private LocalstorageService: LocalstorageService) { }

  getIntegration() {
    return this.integrationService.getIntegration()
  }

  ngOnInit(): void {
    this.getData()
  }  
  
  async getData() {
    this.integration = await this.getIntegration()
    await this.getRdZohoUrls()

    if(this.isLoggedInRd()) {
      await this.getRdFields()
    }
    if(this.isLoggedInZoho()) {
      await this.getZohoFields()
    }
  }

  async getRdZohoUrls() {
    if(!this.integration.rd || !this.integration.rd.accessToken)
      await this.getRdUrl()
    if(!this.integration.zoho || !this.integration.zoho.accessToken)
      await this.getZohoUrl()
  }

  async getZohoUrl() {
    if(!this.integration.zoho || !this.integration.zoho.accessToken) {
      const response = await this.integrationService.getZohoUrl()
      this.zohoUrl = response.url
    }
  }

  async getRdUrl() {
    if(!this.integration.rd || !this.integration.rd.accessToken) {
      const response = await this.integrationService.getRDUrl()
      this.rdUrl = response.url
    }
  }

  async getRdFields() {
    this.rdFields = await this.integrationService.getRdFields()
  }

  async getZohoFields() {
    this.zohoFields = await this.integrationService.getZohoFields()
  }

  authenticateZoho() {
    this.LocalstorageService.goToUrl(this.zohoUrl)
  }

  authenticateRd() {
    this.LocalstorageService.goToUrl(this.rdUrl)
  }

  save() {
    this.setRdFields()
    this.integrationService.save(this.integration)
      .then(() => alert('Integração atualizada com sucesso!'))
      .catch(() => alert('Erro ao atualizar integração'))
  }

  setRdFields() {
    this.integration.fields.forEach((field) => {
      const rdField = this.rdFields.find(rdField => rdField.apiId === field.rd.apiId)
      field.rd.label = rdField.label
    })
  }

  remove(index) {
    this.integration.fields.splice(index, 1)
  }

}
