import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service'
import { LocalstorageService } from '../../../services/local-storage.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  username: string
  password: string
  constructor(private authService: AuthService,
              private router: Router,
              private storage: LocalstorageService) { }

  ngOnInit(): void {
  }

  login() {
    this.authService.login(this.username, this.password)
      .then((response) => {
        this.storage.setItem('session', response.token)
        this.storage.setItem('expiresAt', this.setExpiresAt())
        this.router.navigate([''])
      }).catch((err) => {
        alert('Erro ao fazer login:' + err.message)
      })
  }

  setExpiresAt() {
    return new Date(Date.now() + 86400000).toString()
  }

}
