import { Component, OnInit } from '@angular/core';
import { IntegrationService } from '../../services/integration.service';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css']
})
export class EventsComponent implements OnInit {
  event = {
    rd: {
      sendOpportunity: {
          module: 'Leads',
          active: true,
          url: 'https://integracaozohord.com.br/api/integration/rd/send-to-zoho',
          update: true
      },
      sendUUID: {
          active: true,
          url: 'https://integracaozohord.com.br/api/integration/rd/send-uuid'
      },
      sendUpdate: {
          active: true,
          url: 'https://integracaozohord.com.br/api/integration/rd/send-update'
      }
    },
    zoho: {
        changeEmail: { 
            active: true,
            url: 'https://integracaozohord.com.br/api/integration/zoho/send-email-changed'
        },
        sendWonLost: {
            active: true,
            url: 'https://integracaozohord.com.br/api/integration/rd/win-lost'
        },
        sendLead: {
            active: true,
            url: 'https://integracaozohord.com.br/api/integration/zoho/send-to-rd'
        }
    }
  }

  integration: any = {
    events: this.event
  }
  constructor(private integrationService: IntegrationService) { }

  ngOnInit(): void {
    this.integrationService.getIntegration()
      .then(integration => {
        debugger
        this.integration = integration
        if(!this.integration.events) this.integration.events = this.event
      })
  }

  save() {
    this.integrationService.save(this.integration)
      .then(() => alert('Integração salva com sucesso'))
      .catch(err => alert('Erro ao salvar integração'))
  }

  copy(text) {
    const el = document.createElement('textarea');
    el.value = text;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    alert('Url copiada com sucesso!')
  }

}
