import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IUser } from '../../../models/users'
import { LocalstorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {


  constructor(private http: HttpClient, private storage: LocalstorageService) { }

  login(username: string, password: string): Promise<any> {
    return this.http.post('/api/auth/login', 
      { login: username, password }, 
      { headers: { 'Content-Type': 'application/json' }})
        .toPromise()
  }

  isLoggedIn() {
    let expires = this.storage.getItem('expiresAt')
    if(expires && new Date(expires) > new Date())
      return true

    else
      return false
  }

  logout() {
    this.storage.removeItem('session')
    this.storage.removeItem('expiresAt')
  }
}
