import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { LocalstorageService } from './local-storage.service'
 
@Injectable({
  providedIn: 'root'
})
export class IntegrationService {

  headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + this.localStorage.getItem('session')
  }
  constructor(private http: HttpClient, private localStorage: LocalstorageService) { }

  getIntegration(): Promise<any> {
    return this.http.get('/api/integration', 
      { headers: this.headers }).toPromise()
  }

  getRDUrl(): Promise<any> {
    return this.http.get('/api/rd/get-url', 
      { headers: this.headers }).toPromise()
  }

  getZohoUrl(): Promise<any> {
    return this.http.get('/api/zoho/get-url', 
      { headers: this.headers }).toPromise()
  }

  getRdFields(): Promise<any> {
    return this.http.get('/api/rd/get-fields', {
      headers: this.headers }).toPromise()
  }

  getZohoFields(): Promise<any> {
    return this.http.get('/api/zoho/get-fields', {
      headers: this.headers }).toPromise()
  }

  authorizeRd(code): Promise<any> {
    return this.http.get(`/api/rd/authenticate?code=${code}`, {
      headers: this.headers
    }).toPromise()
  }

  authorizeZoho(code): Promise<any> {
    return this.http.get(`/api/zoho/authenticate?code=${code}`, {
      headers: this.headers
    }).toPromise()
  }

  save(integration): Promise<any> {
    return this.http.post(`/api/integration`, 
    integration, 
    {
      headers: this.headers
    }).toPromise()
  }
}
