import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private authService: AuthService, private router: Router) { }


	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) : boolean {
		if(this.authService.isLoggedIn()){
			return true;
		}
		
		this.router.navigate(['login'], { queryParams: { returnUrl: state.url } });
		return false;
	}

	canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) : boolean {
		return this.canActivate(route, state);
	}
}
