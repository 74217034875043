<div class="row text-center">
    <div class="col-md-6 col-xs-6">
        <div class="card autentication-card" *ngIf="!isLoggedInRd()">
            <h3>Conta não autenticada com RD Station</h3>
            <button class="btn btn-primary auth-button" (click)="authenticateRd()">Autenticar</button>
        </div>
    </div>
    <div class="col-md-6 col-xs-6">
        <div class="card autentication-card" *ngIf="!isLoggedInZoho()">
            <h3>Conta não autenticada com Zoho</h3>
            <button class="btn btn-primary auth-button" (click)="authenticateZoho()">Autenticar</button>
        </div>
    </div>

    <div class="text-center" *ngIf="isLoggedInRd() && isLoggedInZoho()">
        <h3>Configurar campos da integração</h3>
        <div class="card integration-card">
            <div class="row">
                <div class="input-group mb-3">
                    <input type="text" class="form-control" placeholder="Módulo do Zoho a ser integrado" aria-label="Módulo do Zoho a ser integrado" [(ngModel)]="integration.zoho.module">
                </div>                  
            </div>
            <div class="row">
                <div class="col-md-4 col-xs-4">Nome do campo no RD</div>
                <div class="col-md-4 col-xs-4">Nome do campo no Zoho</div>
            </div>
            <div class="row" *ngFor="let field of integration.fields; let i = index">
                <div class="col-xs-5 col-md-5" >
                    <select class="form-select" aria-label="Nome do campo no RD" [(ngModel)]="field.rd.apiId">
                        <option *ngFor="let rdField of rdFields" value="{{rdField.apiId}}">{{rdField.label}}</option>
                    </select>
                </div>
                <div class="col-xs-5 col-md-5" >
                    <select class="form-select" aria-label="Nome do campo no Zoho" [(ngModel)]="field.zoho">
                        <option *ngFor="let zohoField of zohoFields" value="{{zohoField.api_name}}">{{zohoField.field_label}}</option>
                    </select>
                </div>
                <div class="col-xs-2 col-md-2">
                    <button class="btn btn-danger" (click)="remove(i)">
                        <i class="fa fa-trash"></i>
                    </button>
                </div>
            </div>
            <button class="btn btn-primary" (click)="integration.fields.push({ rd: {}})">Adicionar campo</button>
        </div>
        <button class="btn btn-primary" (click)="save()">Salvar</button>
    </div>
</div>