<section class="row">
    <div class="card text-center" style="width: 300px; margin: 0 auto;">
        <!-- <img src="" alt=""> -->
        <div class="card-body">
            <h5 class="card-title">Login</h5>
            <div class="input-group">
                <input class="col-xs-12 col-md-12" type="text" name="login" class="form-control" placeholder="username" [(ngModel)]="username">
            </div>
            <div class="input-group">
                <input class="col-xs-12 col-md-12" type="password" name="password" class="form-control" placeholder="senha" [(ngModel)]="password">
            </div>
            <button class="btn btn-primary" (click)="login()">Entrar</button>
        </div>
    </div>
</section>